import React from 'react'

function HeaderHtml() {

    const menuItems = [
        { name: 'Sobre', href: '#about'},
        { name: 'Experiência', href: '#experience'},
        { name: 'Educação', href: '#education'},
        { name: 'Projetos', href: '#projects'},
        { name: 'Skills', href: '#skills'},
        { name: 'Contato', href: '#contact'},
        { name: 'Tutoriais', href: '#'},
        //{ name: 'Tutoriais', href: 'https://www.notion.so/ruben-com-br/Tutorias-607073cd7d9d4f8cb541d0b96127d7b6?pvs=4', newTab: true},
    ];
    return (
        <header>
            <div id="mobile-menu-close">
                <span>Close</span> <i class="fa fa-times" aria-hidden="true"></i>
            </div>
            <ul id="menu" class="shadow">
                {menuItems.map((item) => (
                    <li key={item.href}>
                        <a href={item.href} target={item.newTab ? "_blank" : "_self"}>{item.name}</a>
                    </li>
                ))}
            </ul>
        </header>
    )
}

export default HeaderHtml