import React from 'react'
import Menu from '../components/Menu'
import HeaderHtml from '../components/HeaderHtml'
import LeadAbout from '../components/LeadAbout'
import Experience from '../components/Experience'
import Education from '../components/Education'
import Skills from '../components/Skills'
import Footer from '../components/Footer'
import Projetos from '../components/Projetos'
import Contato from '../components/Contato'

function Home() {

    return (
        <>
            <Menu />
            <HeaderHtml />
            <LeadAbout />
            <Experience />
            <Education />

            <div id="projects" className="background-alt">
                <h2 className="heading">Projetos</h2>
                <div className="container">
                    <Projetos />
                    <Skills />
                    <Contato />
                    <Footer />
                </div>
            </div>
        </>
    )
}

export default Home
