import React from 'react';

function Projetos() {
  const projetos = [
    {
      imagem: 'images/project.jpg',
      nome: 'Site Portfolio',
      descricao: 'Site Portfolio com ótimo visual feito em React + BootStrap',
      link: '#',
      skills: [ 'HTML', 'CSS', 'JavaScript', 'ReactJS', 'BootStrap', 'Style Components'],
      github: 'https://github.com/ruben-com-br/site-pessoal-react'
    }
  ];

  return (
    <div>
      <div className="row">
        {projetos.map((projeto, index) => (
          <div key={index} className="project shadow-large">
            <div className="project-image">
              <img src={projeto.imagem} />
            </div>
            <div className="project-info">
              <h3>{projeto.nome}</h3>
              <p>{projeto.descricao}</p>
              <a href={projeto.link}>Link</a><br/>
              <a href={projeto.github}>Github</a>
              <p>Skills: {projeto.skills.map((skill, index) => ( `${skill}, ` ))}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Projetos;
