import React from 'react'

function Education() {

    const educationData = [
        {
            institution: 'Pontifícia Universidade Católica de Goiás',
            date: 'Ago 2006 - Ago 2009',
            course: 'Bacharelado em Ciência da Computação',
            description: 'Estruturas de dados, linguagem baixo nível, C, Assembly, C++',
        },
        {
            institution: 'Universidade Federal de Goiás',
            date: 'Janeiro 2010 - Dezembro 2014',
            course: 'Bacharelado em Engenharia de Software',
            description: 'Curso voltado a todas as etapas de Engenharia de Software e boas praticas de programação, Java, Android, e estágio em Fabrica de Software',
        },
    ];


    return (
        <div>
            <div id="education">
                <h2 class="heading">Educação</h2>
                {educationData.map((education, index) => (
                    <div className="education-block" key={index}>
                        <h3>{education.institution}</h3>
                        <span className="education-date">{education.date}</span>
                        <h4>{education.course}</h4>
                        <p>{education.description}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Education