import React from 'react'

function Experience() {

    const experiences = [
        {
            id: 1,
            title: 'Freelancer',
            company: '',
            description: 'Desenvolvedor Freelancer, Python, Java, C/C++, Node, VBA, PHP, C# .net, Angular, Vue, React, ReactNative, Flutter, Kotlin, DevOps, Html, CSS',
            date: 'Agosto 2006 – Janeiro 2019',
            show: true
        },
        {
            id: 2,
            title: 'IBGE',
            company: 'Gerência/Desenvolvedor Python',
            description: `• Contrato de trabalho em órgão federal. 
• Gerenciamento de Agências a nível estadual,
• Excel Avançado / Macros, Banco de dados, Sqlite, Oracle
• Automatização de Tarefas PyAutoGui e Powershell, WebScrapping,
• Django, Flask, análise de dados com Python, geração de gráficos
• Criação de Páginas html, css, js, Angular, Vue`,
            date: 'Fevereiro 2019 – Dezembro 2020',
            show: true
        },
        {
            id: 3,
            title: 'FullStack',
            company: 'React/Java',
            description: 'Criação / Manutenção de CRM, criação de páginas, API, consulta no banco. BackEnd Java JPA/Sprint boot. FrontEnd ReactJS (web), AntDesign, Autenticação OAuth2, GIT/Gitlab, Postman/Swagger',
            date: 'Setembro 2021 – Dezembro 2021',
            show: true
        },
        {
            id: 4,
            title: 'Desenvolvedor FullStack Node/React',
            company: 'Etho Soluções',
            description: 'BootCamp, React JS, TypeScripts, Styled Components, Node, AWS',
            date: 'Dezembro 2021 – Janeiro 2022',
            show: true
        },
        {
            id: 4,
            title: 'Accenture',
            company: 'Aplication Development',
            description: `Desenvolvimento em varios projetos, em varias tecnologias, e varios projetos, predominando Java SpringBoot/Quarkus
            
            02/2022 - 03/2022
            Empresa: Projeto Interno Accenture
            Descrição: Aplicativo de Relatorios de Projetos
            Skills: Node, Docker, GIT, AWS
            
            04/2022 - 04/2022
            Treinamentos Interno
            Flutter startto.dev
            
            05/2022 - 10/2022
            Empresa: Empresa de Viagens e Turismo
            Descrição: Sistema de Pontuação e Fidelidade
            Skills: Java 11, SpringBoot, MicroServiços, Testes, GIT, Kafka, MongoDB, Agile/Jira
            
            11/2022 - 03/2023
            Empresa: Fabricante de Automoveis
            Descrição: Aplicativo de Motorista sobre informações do Veiculo. Conversão de Node para Java, Manutenção e adição de novas Features
            Skills: Java 11, SpringBoot, Testes, GIT, Oracle
            
            Descrição: Sistema de Agendamento de Serviço de Oficina. Criação de um sistema da America Latina, Unificando Sistema diferentes de vários países.
            Skills: Java 11, SpringBoot, Testes, GIT, Oracle, MicroServiços
            
            04/2023 - 04/2023
            Empresa: Rede Farmaceutica
            Descrição: Sistema de cadastro de promoções e fidelidade de produtos
            Skills: Java 11, SpringBoot, Testes, GIT
                        
            05/2023 - 08/2023
            Empresa: Empresa Setor Bancario
            Descrição: Refatoração de Sistema de Cartões Antigo, parte Debito
            Skills: Java 11, Quarkus, ApacheCamel, Testes, GitHub, AWS, Agile/Jira, BDD

            09/2023 - 09/2023
            Empresa: Projeto Interno Accenture
            Descrição: Sistema de agendamento de Reuniões
            Skills: Vue.js, Vuetify, MSAL, C#, SQLServer, Azure, Docker, Testes
            
            10/2023 - 03/2024
            Empresa: Setor Financeiro
            Descrição: Site / Aplicativo / Api de Empresitmos pessoais
            Skills: IntelliJ, Java, Spring Boot, Junit, Mockito, Azure, Rest, GraphQL, Kafka, Postman, SQLServer, GitHub Copilot, Agile, Scrum, Git`,
            date: 'Fevereiro 2022 – Marco 2024',
            show: true
        }
    ];

    return (
        <div>
            <div id="experience" className="background-alt">
                <h2 className="heading">Experience</h2>
                <div id="experience-timeline">
                    {experiences.map((experience) => (
                        <div className="vtimeline-point" key={experience.id}>
                            <div class="vtimeline-icon">
                                <i class="fa fa-map-marker"></i>
                            </div>
                            <div className="vtimeline-block">
                                <span className="vtimeline-date">{experience.date}</span>
                                <div data-date="{experience.date}" class="vtimeline-content">
                                    <h3>{experience.title}</h3>
                                    <h4>{experience.company}</h4>
                                    <p style={{ whiteSpace: 'pre-line' }}>{experience.description}</p>
                                    
                                </div>
                            </div>
                        </div>
                    ))}
                  
                </div>
            </div>
        </div>
    )
}

export default Experience

