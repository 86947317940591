import React from 'react'

function Contato() {
    return (
        <div>
            <div id="contact">
                <h2>Entre em Contato</h2>
                <div id="contact-form">
                    <form method="POST" action="https://formspree.io/rubencadastros@gmail.com">
                        <input type="hidden" name="_subject" value="Contact request from personal website" />
                        <input type="email" name="_replyto" placeholder="Seu Email" required />
                        <textarea name="message" placeholder="Sua messagem" required></textarea>
                        <button type="submit">Enviar</button>
                    </form>

                </div>

                {/* <!-- End #contact-form --> */}
            </div>
        </div>
    )
}

export default Contato