import { linkCv } from "../components/LinkCV";
import { useEffect } from 'react';


export function CV() {
    useEffect(() => {
        window.location.href = linkCv;
      }, []);
    
      return null;
}

