import Home from "./pages/Home";
import { CV } from "./pages/CV";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

function App() {
  return (
    <>
      <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/cv2" element={<CV />} >
        </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
