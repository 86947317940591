import React from 'react';

const skills = [
  'JavaScript',
  'Python',
  'Java',
  'Spring Boot',
  'Node.js',
  'React',
  'React Native',
  'Flutter',
  'Vue'
];

function Skills() {
  return (
    <div>
      <div id="skills">
        <h2 className="heading">Skills</h2>
        <ul>
          {skills.map((skill, index) => (
            <li key={index}>{skill}</li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Skills;