import React from 'react'

function Footer() {

    const ir_para_topo = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const socialLinks = [
        {
          iconClass: 'fa fa-github',
          link: 'https://github.com/rubengyn',
          label: 'GitHub'
        },
        {
          iconClass: 'fa fa-stack-overflow',
          link: 'https://pt.stackoverflow.com/users/127670/ruben-borges-ramos',
          label: 'Stack Overflow'
        },
        {
          iconClass: 'fa fa-linkedin',
          link: 'https://www.linkedin.com/in/ruben-com-br/',
          label: 'LinkedIn'
        },
        {
          iconClass: 'fa fa-facebook',
          link: 'https://www.facebook.com/',
          label: 'Facebook'
        },
        {
          iconClass: 'fa fa-twitter',
          link: 'https://twitter.com/',
          label: 'Twitter'
        },
        {
          iconClass: 'fa fa-google-plus',
          link: 'https://plus.google.com/',
          label: 'Google Plus'
        }
      ];
    return (
        <div>
            <footer>
                <div class="container">
                    <div class="row">
                        <div class="col-sm-5 copyright">
                            <p>
                                Copyright &copy; 2020 RÚBEN BORGES RAMOS
                            </p>
                        </div>
                        <div class="col-sm-2 top">
                            <span id="to-top" onClick={ir_para_topo}>
                                <i class="fa fa-chevron-up" aria-hidden="true"></i>
                            </span>
                        </div>
                        <div class="col-sm-5 social">
                            <ul>
                                {socialLinks.map(link => (
                                    <li key={link.label}>
                                        <a href={link.link} target="_blank">
                                            <i className={link.iconClass} aria-hidden="true"></i>
                                        </a>
                                    </li>
                                ))}

                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default Footer