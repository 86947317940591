import React, { useRef } from 'react'
import { linkCv } from './LinkCV';

function LeadAbout() {
    const scrollToNextElement = () => {
        const elementoLead = document.getElementById('lead');
        const proximoElementoDoLead = elementoLead.nextElementSibling;
        const offSetProximoElemento = proximoElementoDoLead.offsetTop;
        
        window.scrollTo({
            top: offSetProximoElemento,
            behavior: 'smooth'
        });
    }
    const profile = {
        nome : "Ruben Ramos",
        titulo : "Engenheiro de Software", 
        tituloCV : "Baixar CV",
        titleAbout : "Sobre",
        descriptionAbout : "Engenheiro de Software pela UFG, Desenvolvedor FullStack (Java/Kotlin, Python, Node, JavaScript, Angular, React, Vue)"

    }
    return (
        <div>
            <div id="lead">
                <div id="lead-content">
                    <h1>{profile.nome}</h1>
                    <h2>{profile.titulo}</h2>
                    <a href={linkCv} class="btn-rounded-white">{profile.tituloCV}</a>
                </div>

                <div id="lead-overlay"></div>

                <div id="lead-down" onClick={scrollToNextElement}>
                    <span >
                        <i className="fa fa-chevron-down" aria-hidden="true"></i>
                    </span>
                </div>

            </div>
            <div id="about">
                <div class="container">
                    <div class="row">
                        <div class="col-md-4">
                            <h2 class="heading">{profile.titleAbout}</h2>
                        </div>
                        <div class="col-md-8">
                            <p>
                                {profile.descriptionAbout}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LeadAbout
