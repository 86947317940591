import React from 'react'

function Menu() {
    return (
        <div> <div id="mobile-menu-open" class="shadow-large">
            <i class="fa fa-bars" aria-hidden="true"></i>
        </div></div>
    )
}

export default Menu